<template>
    <div id="page-lap-kunjungan-pasien">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      ID
                    </template>
                    <b-form-input
                      type="text"
                      v-model="idnya"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tanggal
                    </template>
                    <b-form-input
                      type="text"
                      v-model="tanggalnya"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      ID Pelayanan
                    </template>
                    <b-form-input
                      type="text"
                      v-model="pelayanan"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Nama Pasien
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.nama_lengkap"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tanggal Pelayanan
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.tanggal_pelayanan"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli/Ruangan
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.nama_poli"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      No. eRM
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.no_rm"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      NIK
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.nik"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Jenis Kelamin
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.jenis_kelamin"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Umur
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.umur"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Asuransi
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.asuransi"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      No. HP Pasien
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.no_hp_pasien"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      No. Rujukan
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.no_rujukan"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Nama Dokter
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.nama_dokter"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Nama Perawat
                    </template>
                    <b-form-input
                      type="text"
                      v-model="data_kunjungan.nama_perawat"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kriteria Khusus
                    </template>
                    <b-form-group>
                        <b-form-radio-group
                            id="radio-group-1"
                            v-model="kriteria_khusus"
                            :options="options_kriteria"
                            name="radio-options"
                            @change="getPoliRujuk()"
                        ></b-form-radio-group>
                    </b-form-group>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tanggal Estimasi
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      :min="min"
                      locale="id"
                      v-model="tanggal_estimasi"
                    ></b-form-datepicker>
                  </b-form-group>
                  <b-form-group v-if="kriteria_khusus == 'spesialis'" label-cols-md="4">
                    <template v-slot:label>
                      Poli Rujukan
                    </template>
                    <multiselect
                      :options="options_poli_rujukan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama"
                      size="sm"
                      v-model="poli_rujukan"
                      @input="getSubSpesialis(poli_rujukan)"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group v-else label-cols-md="4">
                    <template v-slot:label>
                      Poli Rujukan
                    </template>
                    <multiselect
                      :options="options_poli_rujukan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_poli_rujuk"
                      size="sm"
                      v-model="poli_rujukan"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group v-if="poli_rujukan && kriteria_khusus == 'spesialis'" label-cols-md="4">
                    <template v-slot:label>
                      Sub Spesialis
                    </template>
                    <multiselect
                      :options="options_sub_spesialis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      size="sm"
                      label="nama_sub_spesialis"
                      v-model="sub_spesialis"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sarana
                    </template>
                    <multiselect
                      :options="options_sarana"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_sarana"
                      size="sm"
                      v-model="sarana"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group v-if="data_kunjungan.asuransi == 'BPJS'">
                    <b-button @click="getRS()" :disabled="!poli_rujukan || !tanggal_estimasi" variant="success">Get RS Tujuan</b-button>
                  </b-form-group>

                  <b-form-group v-if="data_kunjungan.asuransi == 'BPJS'"  label-cols-md="4">
                    <template v-slot:label>
                      RS Tujuan Rujukan
                    </template>
                    <multiselect
                      :options="options_rs_rujukan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama"
                      size="sm"
                      v-model="rs_rujukan"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group v-else label-cols-md="4">
                    <template v-slot:label>
                      RS Tujuan Rujukan
                    </template>
                    <b-form-input
                      type="text"
                      v-model="rs_rujukan_umum"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Alasan Rujukan
                    </template>
                    <b-form-textarea
                      v-model="alasan_rujukan"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-end; align-items: flex-end;">
                    <b-button v-if="!is_update" :disabled="disabledButton" @click="simpanExternal()" variant="primary" class="mr-2">Simpan</b-button>
                    <b-button v-else :disabled="disabledButton" @click="updateExternal()" variant="warning" class="mr-2">Update</b-button>
                    <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button>
                    <b-button variant="warning">Reset</b-button> -->
                    <b-button v-if="rujuk_external_id" @click="printRujukan()" variant="success" class="mr-2">Cetak Surat Rujukan BPJS</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <div style="display: none;" id="surat_rujukan_bpjs">
          <section class="content-print A4" v-if="dataPrint">
            <div class="sheet">
              <div class="w-100-flex">
                <div style="width: 50%;display: flex;justify-content: flex-start;align-items: center;">
                  <div style="width: 80%;height: 10mm;display: flex;justify-content: center;align-items: center;">
                    <!-- <h6>IMG BPJS</h6> -->
                    <img src="../../../../assets/2560px-BPJS_Kesehatan_logo.svg.png" alt="" style="width: 100%;">
                  </div>
                </div>
                <div style="width: 50%;">
                  <table class="custom-table">
                    <tbody>
                      <tr>
                        <td style="width: 40mm;font-weight: bold;">Kedeputian Wilayah</td>
                        <td style="width: 5mm;">:</td>
                        <td>{{ dataPrint.ppk.kc.kdKR.nmKR }}</td>
                      </tr>

                      <tr>
                        <td style="font-weight: bold;">Kantor Cabang</td>
                        <td>:</td>
                        <td>{{ dataPrint.ppk.kc.nmKC }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="w-100" style="margin-top: 5mm;">
                <h6 style="font-size: 14pt;text-align: center;"><strong>Surat Rujukan FKTP</strong></h6>
              </div>

              <div class="custom-border-paper" style="padding:2.5mm;margin-top: 5mm;">
                <div class="w-100-flex custom-border-paper">
                  <div style="width: 50%;">
                    <table class="custom-table">
                      <tbody>
                        <tr>
                          <td style="width: 35mm;">No. Rujukan</td>
                          <td style="width: 2.5mm;">:</td>
                          <td>{{ dataPrint.noRujukan }}</td>
                        </tr>
      
                        <tr>
                          <td>FKTP</td>
                          <td>:</td>
                          <!-- <td><span>Jepang (0986543)</span></td> -->
                          <td>{{ dataPrint.ppk.nmPPK }} ({{ dataPrint.ppk.kdPPK }})</td>
                        </tr>
      
                        <tr>
                          <td>Kabupaten/Kota</td>
                          <td>:</td>
                          <!-- <td><span>KAB. KUDUS (0987654)</span></td> -->
                          <td>{{ dataPrint.ppk.kc.dati.nmDati }} ({{ dataPrint.ppk.kc.dati.kdDati }})</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style="width: 50%;display: flex;justify-content: center;align-items: center;">
                    <div style="width: 90%;height: 10mm;border:1px solid black;display: flex;justify-content: center;align-items: center;">
                      <!-- <h6>barcode</h6> -->
                      <barcode :value="dataPrint.noRujukan" :height="50" :displayValue="false">
                        **{{ dataPrint.noRujukan }}**
                      </barcode>
                    </div>
                  </div>
                  
                </div>
            
                <div class="w-100" style="padding:2.5mm">
                  <table class="custom-table" >
                    <tbody>
                      <tr>
                        <td style="width: 50mm;padding-left: 0;">Kepada Yth. TS Dokter</td>
                        <td style="width: 2.5mm;">:</td>
                        <td>{{ dataPrint.poli.nmPoli }}</td>
                      </tr>

                      <tr>
                        <td style="padding-left: 0;">Di</td>
                        <td>:</td>
                        <td>{{ dataPrint.ppkRujuk.nmPPK }}</td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>

                <div class="w-100" style="padding:2.5mm">
                  <h6>Mohon pemeriksaan dan penanganan lebih lanjut pasien :</h6>
                </div>

                <div class="w-100-flex" style="padding:2.5mm">
                  <div style="width: 45%;">
                    <table class="custom-table" >
                      <tbody>
                        <tr>
                          <td style="width: 32mm;padding-left: 0;">Nama</td>
                          <td style="width: 2.5mm;">:</td>
                          <td>{{ dataPrint.nmPst }}</td>
                        </tr>
        
                        <tr>
                          <td style="padding-left: 0;">No. Kartu BPJS</td>
                          <td>:</td>
                          <td>{{ dataPrint.nokaPst }}</td>
                        </tr>

                        <tr v-if="dataPrint.diag1">
                          <td style="padding-left: 0;">Diagnosa</td>
                          <td>:</td>
                          <td>
                            <span style="color: black">{{ dataPrint.diag1.nmDiag }} ({{ dataPrint.diag1.kdDiag }})</span>
                            <span v-if="dataPrint.diag2" style="color: black">, {{ dataPrint.diag2.nmDiag }} ({{ dataPrint.diag2.kdDiag }})</span>
                            <span v-if="dataPrint.diag3" style="color: black">, {{ dataPrint.diag3.nmDiag }} ({{ dataPrint.diag3.kdDiag }})</span>
                          </td>
                          <!-- <td><span>Non-insulin-dependent diabetes mellitus with unspecified complication (E11.8), Essential (Primary)</span></td> -->
                        </tr>

                        <tr>
                          <td style="padding-left: 0;">Telah diberikan</td>
                          <td>:</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div style="width: 55%;">
                    <table class="custom-table" >
                      <tbody>
                        <tr>
                          <td style="width: 15mm;">Umur</td>
                          <td style="width: 2.5mm;">:</td>
                          <td>{{ getTahun(dataPrint.tglLahir) }}</td>
                          <td style="width: 10mm;">Tahun</td>
                          <td style="width: 5mm;">:</td>
                          <td>{{ dataPrint.tglLahir }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="custom-table" border="0">
                      <tbody>
                        <tr>
                          <td style="width: 15mm;vertical-align: middle;">Status</td>
                          <td style="width: 2.5mm;vertical-align: middle;">:</td>
                          <td style="width: 10mm;vertical-align: middle;">
                            <div style="width: 10mm;height: 30px;border: 1px solid black;display: flex;justify-content: center;align-items: center;">
                              {{ dataPrint.pisa }}
                            </div>
                          </td>
                          <td style="vertical-align: middle;width: 35mm;">Utama/Tanggungan</td>
                          <td style="width: 10mm;vertical-align: middle;">
                            <div style="width: 10mm;height: 30px;border: 1px solid black;display: flex;justify-content: center;align-items: center;">
                              {{ dataPrint.sex }}
                            </div>
                          </td>
                          <td style="vertical-align: middle;">(L/P)</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="custom-table" border="0">
                      <tbody>
                        <tr>
                          <td style="width: 15mm;">Catatan</td>
                          <td style="width: 2.5mm;">:</td>
                          <td>{{ dataPrint.catatan || '-' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="w-100-flex" style="padding:2.5mm">
                  <div style="width: 75%;">
                    <h6>Atas bantuannya, diucapkan terima kasih</h6>
                    <table class="custom-table" >
                      <tbody>
                        <tr>
                          <td style="width: 50mm;padding-left: 0;">Tgl. Rencana Berkunjung</td>
                          <td style="width: 2.5mm;">:</td>
                          <td>{{ dataPrint.tglEstRujuk }}</td>
                        </tr>
      
                        <tr>
                          <td style="padding-left: 0;">Jadwal Praktek</td>
                          <td>:</td>
                          <!-- <td><span>Senin :08:00 - 11:00, 14:15 - 15:15</span></td> -->
                          <td>{{ dataPrint.jadwal }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <h6>Surat Rujukan berlaku 1 [satu] kali kunjungan, berlaku sampai dengan : {{ dataPrint.tglAkhirRujuk }}</h6>
                  </div>
                  <div style="width: 25%;">
                    <h6 style="text-align: center;">Salam sejawat,</h6>
                    <h6 style="text-align: center;">{{ dataPrint.tglKunjungan }}</h6>
                    <br/>
                    <br/>
                    <br/>
                    <h6 style="text-align: center;">{{ dataPrint.dokter.nmDokter }}</h6>
                  </div>
                </div>
              </div>
              <div class="custom-border-paper" style="padding:2.5mm">
              

                <div class="w-100" style="padding:2.5mm">
                  <h6 style="font-size: 14pt;text-align: center;"><strong><u>SURAT RUJUKAN BALIK</u></strong></h6>
                </div>

                <div class="w-100" style="padding:2.5mm">
                  <h6>Teman Sejawat Yth.</h6>
                  <h6>Mohon kontrol selanjutnya penderita :</h6>
                  <div class="w-100" style="padding-left: 15mm;margin-top: 5mm;margin-bottom: 5mm;">
                    <table class="custom-table" style="">
                      <tbody>
                        <tr>
                          <td style="width: 32mm;padding-left: 0;">Nama</td>
                          <td style="width: 2.5mm;">:</td>
                          <td>{{ dataPrint.nmPst }}</td>
                        </tr>

                        <tr>
                          <td style="padding-left: 0;">Diagnosa</td>
                          <td>:</td>
                          <td>......................................................................</td>
                        </tr>

                        <tr>
                          <td style="padding-left: 0;">Terapi</td>
                          <td>:</td>
                          <td>......................................................................</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h6>Tindak lanjut yang dianjurkan</h6>
                </div>

                <div class="w-100-flex" style="padding:2.5mm">
                  <div style="width: 60%;background-color: ;">
                    <table class="custom-table" border="0">
                      <tbody>
                        <tr>
                          <td style="vertical-align: middle;width: 15mm;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                          <td style="vertical-align: middle;">Pengobatan dengan obat-obatan :</td>
                        </tr>

                        <tr>
                          <td>&nbsp;</td>
                          <td style="vertical-align: middle;">
                            ..................................................................
                          </td>
                        </tr>

                        <tr>
                          <td style="vertical-align: middle;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                          <td style="vertical-align: middle;">Kontrol kembali ke RS tanggal : ..............</td>
                        </tr>

                        <tr>
                          <td style="vertical-align: middle;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                          <td style="vertical-align: middle;">Lain-lain : ..................................................</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style="width: 40%;">
                    <table class="custom-table" border="0">
                      <tbody>
                        <tr>
                          <td style="vertical-align: middle;width: 15mm;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                          <td style="vertical-align: middle;">Perlu Perawatan</td>
                        </tr>
                        <tr>
                          <td style="vertical-align: middle;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                          <td style="vertical-align: middle;">Konsultasi selesai</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="custom-table" border="0">
                      <tbody>
                        <tr>
                          <td>{{ $store.state.puskesmas.nama_kota }} tgl ..............................</td>
                          <!-- <td>........................ tgl ..............................</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="w-100-flex" style="padding:2.5mm;page-break-inside: avoid;">
                  <div style="width: 60%;">
                    &nbsp;
                  </div>
                  <div style="width: 40%;">
                    <h6 style="text-align: center;">Dokter RS,</h6>
                    <br/>
                    <br/>
                    <br/>
                    <h6 style="text-align: center;">( ............................................. )</h6>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect";
  import VueBarcode from 'vue-barcode';
  export default {
    name: "kunjungan_pasien",
    components: {
      Multiselect,
      'barcode': VueBarcode,
    },
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth())
      minDate.setDate(minDate.getDate())
      return {
        min: minDate,
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        dataPrint: null,
        data_kunjungan: {
            nama_lengkap: null,
            tanggal_pelayanan: null,
            nama_poli: null,
            no_rm: null,
            nik: null,
            jenis_kelamin: null,
            umur: null,
            asuransi: null,
            nama_dokter: null,
            nama_perawat: null,
            no_rujukan: null,
            no_hp_pasien: null,
        },
        options_poli_rujukan: [],
        options_sub_spesialis: [],
        options_rs_rujukan: [],
        options_sarana: [],
        sarana: null,
        poli_rujukan: null,
        sub_spesialis: null,
        is_update: false,
        rs_rujukan: null,
        rs_rujukan_umum: null,
        tanggal_estimasi: null,
        alasan_rujukan: null,
        disabledButton: false,
        idnya: "Otomatis",
        tanggalnya: null,
        pelayanan: "Otomatis",
        rujuk_external_id: null,
        options_diagnosa: [],
        kriteria_khusus: "spesialis",
        options_kriteria: [
            {value: "spesialis", text: "Spesialis"},
            {value: "khusus", text: "Khusus"},
        ],
      };
    },
    computed: {
      
    },
    activated(){
        this.tanggalnya = this.$moment(new Date()).format("YYYY-MM-DD HH:mm")
        this.getData()
        this.disabledButton = false
        if(this.$route.params.id) {
            this.getDataKunjungan()
        }
    },
    // async mounted() {
    //   // Set the initial number of items
    //   this.totalRows = this.items.length;
    //   this.getDatas();
    // },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData(){
        let vm = this
        let poli_rujuk = await vm.$axios.post("/spesialis/list")
        // console.log(poli_rujuk, 'tes');
        if(poli_rujuk.data.data.length){
            vm.options_poli_rujukan = poli_rujuk.data.data
            for (let i = 0; i < vm.options_poli_rujukan.length; i++) {
                let x = vm.options_poli_rujukan[i];
                x.nama = `${x.kd_spesialis} - ${x.nm_spesialis}`
            }
        }
        let sarana = await vm.$axios.post("/ms_sarana/list")
        console.log(sarana, 'tes');
        if(sarana.data.data.length){
            vm.options_sarana = sarana.data.data
            
        }
      },
      async printRujukan() {
        const vm = this
        let x = {
          rujuk_external_id: vm.rujuk_external_id,
        }
        
        const options_A4 = {
          name: '_blank',
          specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
          ],
          styles: [
          '/style-paper-a4.css',
          ],
          timeout: 1000, // default timeout before the print window appears
          autoClose: true, // if false, the window will not close after printing
          windowTitle: localStorage.getItem("nama_puskesmas"), // override the window title
        }
        try {
          let res = await vm.$axios.post('/rujuk_external/surat_rujukan_FKTP', x)
          if (res.data.status == 200) {
            vm.dataPrint = res.data.data[0]
            vm.dataPrint.umur_pasien = vm.getTahun(vm.dataPrint.tglLahir)
            // console.log(vm.dataPrint);
            if (vm.dataPrint) {
              await vm.$htmlToPaper('surat_rujukan_bpjs', options_A4);
            }
          }
        } catch (err) {
          console.log(err)
        }
      },
      getTahun(val) {
        let a = this.$moment();
        let b = this.$moment(val, 'DD-MM-YYYY');
        let diffDuration = this.$moment.duration(a.diff(b));
        return diffDuration.years()
      },
      simpanExternal(){
        let vm = this
        let kd_sarana = null
        let sarana_id = null
        if(vm.sarana){
          kd_sarana = vm.sarana.kode_sarana
          sarana_id = vm.sarana.ms_sarana_id
        }
        console.log(vm.kunjungan,'kunjungan');
        if(vm.data_kunjungan.asuransi == "BPJS"){
          if(vm.kriteria_khusus == "spesialis"){
            let ms_poli_rujuk_id = null
            let kd_sub_spesialis = null
            if(vm.sub_spesialis){
              ms_poli_rujuk_id = vm.sub_spesialis.ms_poli_rujuk_id
              kd_sub_spesialis = vm.sub_spesialis.kode_sub_spesialis
            }
            vm.$axios.post("/rujuk_external/register", {
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: ms_poli_rujuk_id,
              kd_sub_spesialis: kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kdppk: vm.rs_rujukan.kdppk,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              console.log(res, 'ini respon');
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }
              else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.data.data.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }else {
            vm.$axios.post("/rujuk_external/register", {
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: vm.poli_rujukan.ms_poli_rujuk_id,
              kd_khusus: vm.poli_rujukan.kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kdppk: vm.rs_rujukan.kdppk,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }
              else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.data.data.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }
        }else if(vm.data_kunjungan.asuransi == "UMUM"){
          if(vm.kriteria_khusus == "spesialis"){
            let ms_poli_rujuk_id = null
            let kd_sub_spesialis = null
            if(vm.sub_spesialis){
              ms_poli_rujuk_id = vm.sub_spesialis.ms_poli_rujuk_id
              kd_sub_spesialis = vm.sub_spesialis.kode_sub_spesialis
            }
            vm.$axios.post("/rujuk_external/register", {
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan_umum,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: ms_poli_rujuk_id,
              kd_sub_spesialis: kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }else {
            vm.$axios.post("/rujuk_external/register", {
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan_umum,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: vm.poli_rujukan.ms_poli_rujuk_id,
              kd_khusus: vm.poli_rujukan.kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }
        }
        
      },
      updateExternal(){
        let vm = this
        let kd_sarana = null
        let sarana_id = null
        if(vm.sarana){
          kd_sarana = vm.sarana.kode_sarana
          sarana_id = vm.sarana.ms_sarana_id
        }
        console.log(vm.kunjungan,'kunjungan');
        if(vm.data_kunjungan.asuransi == "BPJS"){
          if(vm.kriteria_khusus == "spesialis"){
            let ms_poli_rujuk_id = null
            let kd_sub_spesialis = null
            if(vm.sub_spesialis){
              ms_poli_rujuk_id = vm.sub_spesialis.ms_poli_rujuk_id
              kd_sub_spesialis = vm.sub_spesialis.kode_sub_spesialis
            }
            vm.$axios.post("/rujuk_external/update", {
              id: vm.kunjungan.rujuk_external_id,
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: ms_poli_rujuk_id,
              kd_sub_spesialis: kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kdppk: vm.rs_rujukan.kdppk,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              console.log(res, 'ini respon');
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }
              else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.data.data.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }else {
            vm.$axios.post("/rujuk_external/update", {
              id: vm.kunjungan.rujuk_external_id,
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: vm.poli_rujukan.ms_poli_rujuk_id,
              kd_khusus: vm.poli_rujukan.kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kdppk: vm.rs_rujukan.kdppk,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }
              else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.data.data.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }
        }else if(vm.data_kunjungan.asuransi == "UMUM"){
          if(vm.kriteria_khusus == "spesialis"){
            let ms_poli_rujuk_id = null
            let kd_sub_spesialis = null
            if(vm.sub_spesialis){
              ms_poli_rujuk_id = vm.sub_spesialis.ms_poli_rujuk_id
              kd_sub_spesialis = vm.sub_spesialis.kode_sub_spesialis
            }
            vm.$axios.post("/rujuk_external/update", {
              id: vm.kunjungan.rujuk_external_id,
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan_umum,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: ms_poli_rujuk_id,
              kd_sub_spesialis: kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }else {
            vm.$axios.post("/rujuk_external/update", {
              id: vm.kunjungan.rujuk_external_id,
              registrasi_id: vm.kunjungan.pendaftaran_id,
              kunjungan_id: vm.kunjungan.kunjungan_id,
              no_hp_pasien: vm.kunjungan.no_telp,
              alasan_rujukan: vm.alasan_rujukan,
              rs_tujuan_rujukan_bpjs: vm.rs_rujukan_umum,
              asuransi: vm.data_kunjungan.asuransi,
              tanggal_estimasi_rujuk: vm.tanggal_estimasi,
              rm_id: vm.kunjungan.rm_id,
              dokter_id: vm.kunjungan.ms_dokter_id,
              perawat_id: vm.kunjungan.ms_perawat_id,
              ms_poli_rujuk_id: vm.poli_rujukan.ms_poli_rujuk_id,
              kd_khusus: vm.poli_rujukan.kd_sub_spesialis,
              ms_sarana_id: sarana_id,
              spesialis_id: vm.poli_rujukan.spesialis_id,
              kd_sarana: kd_sarana,
              no_rujukan : vm.data_kunjungan.no_rujukan
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
                this.getDataKunjungan()
                vm.disabledButton = true
              }else{
                vm.$store.commit('set_alert', {variant: 'danger', msg: res.message, showing: true})
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }
        }
        
      },
      async getPoliRujuk(){
        // console.log(x, 'ini x');
        let vm = this
        if(vm.kriteria_khusus == "khusus"){
            let poli_rujuk = await vm.$axios.post("/ms_poli_rujuk/list")
            // console.log(poli_rujuk, 'tes');
            if(poli_rujuk.data.data.length){
                let poli_rujukan = poli_rujuk.data.data
                vm.options_poli_rujukan = []
                for (let i = 0; i < poli_rujukan.length; i++) {
                    let x = poli_rujukan[i];
                    if(x.is_khusus == true){
                        vm.options_poli_rujukan.push(x)
                    }
                }
                console.log(vm.options_poli_rujukan, 'hasil');
            }
        }else {
            vm.getData()
        }
        
      },
      getSubSpesialis(x){
        let vm = this
        // console.log(x);
        vm.$axios.post("/ms_poli_rujuk/list", {
          halaman: '0',
          jumlah: 1000,
          spesialis_id: x.spesialis_id
        })
        .then((res) => {
          console.log(res, 'sub');

          vm.options_sub_spesialis = []
          for (let i = 0; i < res.data.data.length; i++) {
            let x = res.data.data[i];
            if(x.is_khusus == false){
                vm.options_sub_spesialis.push(x)
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getRS(){
        let vm = this
        // console.log(vm.sub_spesialis);
        // console.log(vm.sarana);

        if(vm.kriteria_khusus == "spesialis"){
          if(!vm.sarana){
              vm.$axios.post("integrasi_pcare/get_faskes_rujukan_sub_spesialis", {
              kode_sub_spesialis: vm.sub_spesialis.kode_sub_spesialis,
              kode_sarana: 0,
              tanggal_estimasi_rujuk: vm.$moment(vm.tanggal_estimasi).format("DD-MM-YYYY")
            })
            .then((res) => {
              // console.log(res, 'tujuan');
              vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL GET DATA", showing: true})
              vm.options_rs_rujukan = res.data.data
              for (let i = 0; i < vm.options_rs_rujukan.length; i++) {
                let x = vm.options_rs_rujukan[i];
                x.nama = `${x.nmppk} (Kapasitas ${x.kapasitas}, Jadwal - ${x.jadwal}) `
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }else{
            vm.$axios.post("integrasi_pcare/get_faskes_rujukan_sub_spesialis", {
            kode_sub_spesialis: vm.sub_spesialis.kode_sub_spesialis,
            kode_sarana: vm.sarana.kode_sarana,
            tanggal_estimasi_rujuk: vm.$moment(vm.tanggal_estimasi).format("DD-MM-YYYY")
            })
            .then((res) => {
              // console.log(res, 'tujuan');
              vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL GET DATA", showing: true})
              vm.options_rs_rujukan = res.data.data
              for (let i = 0; i < vm.options_rs_rujukan.length; i++) {
                let x = vm.options_rs_rujukan[i];
                x.nama = `${x.nmppk} (Kapasitas ${x.kapasitas}, Jadwal - ${x.jadwal}) `
              }
            })
            .catch((err) => {
              console.log(err);
            })
          }
          
        }
      },
      getDataKunjungan(){
        let vm = this
        vm.$axios.get("/kunjungan/details_by_id/" + vm.$route.params.id )
        .then((res) => {
          console.log(res, 'ini respon');
          vm.kunjungan = res.data.data[0]
          vm.data_kunjungan.nama_lengkap = vm.kunjungan.nama_lengkap
          vm.data_kunjungan.nama_poli = vm.kunjungan.nama_poli
          vm.data_kunjungan.tanggal_pelayanan = vm.$moment(vm.kunjungan.waktu_mulai).format("YYYY-MM-DD HH:mm")
          vm.data_kunjungan.no_rm = vm.kunjungan.no_rm
          vm.data_kunjungan.nik = vm.kunjungan.nik
          vm.data_kunjungan.jenis_kelamin = vm.kunjungan.jenis_kelamin
          vm.data_kunjungan.umur = `${vm.kunjungan.usia_tahun} tahun ${vm.kunjungan.usia_bulan} bulan ${vm.kunjungan.usia_hari}`
          if(vm.kunjungan.no_kartu){
            vm.data_kunjungan.asuransi = "BPJS"
          }else {
            vm.data_kunjungan.asuransi = "UMUM"
          }
          if(vm.kunjungan.rujuk_external_id){
            vm.is_update = true
            vm.rujuk_external_id = vm.kunjungan.rujuk_external_id
          }else {
            vm.is_update = false
          }
          vm.data_kunjungan.nama_dokter = vm.kunjungan.nama_dokter
          vm.data_kunjungan.nama_perawat = vm.kunjungan.nama_perawat
          vm.data_kunjungan.no_hp_pasien = vm.kunjungan.no_telp
          vm.data_kunjungan.no_rujukan = vm.kunjungan.no_kunjungan_bpjs
        })
        .catch((err) => {
          console.log(err);
        })
      },
      // async getDataRujukanBPJS() {
      //   const vm = this
      //   try {
      //     let res = await vm.$axios.post('/rujuk_external/surat_rujukan_FKTP')
      //     if (res.data.status == 200) {
      //       vm.dataPrint = res.data.data[0]
      //     }
      //   } catch (err) {
      //     console.log(err)
      //   }
      // },
      
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  };
  </script>
  <style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  #page-lap-kunjungan-pasien .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  }
  </style>
  